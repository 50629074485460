import React from "react";
import { Link as GatsbyLink } from "gatsby";
import { Paragraph, TextContainer, Ul } from "@siteimprove/fancylib";
import { IllustrationSuccess } from "@siteimprove/fancyvisuals";
import {
	Knobs,
	Example,
	DocPageMeta,
	InlineMessage,
	ContentSection,
	HeaderSection,
	ImportExample,
	Header,
	Code,
} from "../../../../../src/docs";
import { LabWarning } from "../../../../../src/docs/docs-lab-warning";
import { Illustration } from "./illustration";

export const Meta: DocPageMeta = {
	category: "Visuals",
	title: "Illustration",
	notepad: "https://hackmd.io/nJNQd0I9TriP2G7R6elNEg",
};
export default (): JSX.Element => (
	<>
		<HeaderSection
			title="Illustration"
			subTitle="Illustrations visually enhance content and concepts, creating engaging experiences."
		/>
		<ContentSection>
			<TextContainer article>
				<LabWarning />
				<InlineMessage variant="warning">
					<Paragraph>
						Looking for a specific illustration? Visit the{" "}
						<GatsbyLink to="/lab/components/Visuals/Illustrations Overview">
							Illustrations Overview
						</GatsbyLink>{" "}
						page for a complete overview of all available illustrations.
					</Paragraph>
				</InlineMessage>
				<ImportExample lab component={Illustration} />
				<Header.H2>Purpose</Header.H2>
				<Paragraph>
					Illustrations are valuable assets when used intentionally. They should be clear, relevant,
					and enhance the user's experience, not detract from it.
				</Paragraph>
				<Paragraph>Illustrations can:</Paragraph>
				<Ul
					items={[
						<>
							<b>Enhance Understanding</b>: Clarify complex concepts, processes, or data.
						</>,
						<>
							<b>Create Emotional Connection</b>: Evoke specific moods, feelings, or brand
							personality.
						</>,
						<>
							<b>Add Visual Interest</b>: Break up text-heavy layouts and guide the user's eye.
						</>,
						<>
							<b>Provide Visual Cues</b>: Direct attention, suggest actions, or enhance navigation.
						</>,
					]}
				/>
				<Header.H2>Examples</Header.H2>
				<Example fn={BasicUsage} />
			</TextContainer>
			<TextContainer article>
				<Header.H2>Sizes</Header.H2>
				<Paragraph>
					Illustrations can be used in various sizes to fulfill different purposes:
				</Paragraph>
				<Ul
					items={[
						<>
							<b>xSmall / Small</b>(Icons/ pictorgrams): Used for visual cues, actions, or within
							text.
						</>,
						<>
							<b>Medium</b>(Supporting): Reinforce content within sections, cards, or lists.
						</>,
						<>
							<b>Large</b> (Hero/Header): Introduce sections, create a visual anchor, or stand
							alone.
						</>,
					]}
				/>
				<Example fn={SizeExample} />
			</TextContainer>
			<TextContainer article>
				<Header.H2>Decorative</Header.H2>
				<Paragraph>
					Decorative illustrations are purely aesthetic and do not convey essential information.
					They should be used sparingly to avoid clutter and maintain visual hierarchy.
				</Paragraph>
				<Example fn={DecorativeExample} />
			</TextContainer>
			<TextContainer article>
				<Header.H2>Properties</Header.H2>
				<Knobs
					component={Illustration}
					initialProps={{ src: IllustrationSuccess, alt: "Illustration example" }}
					exampleValues={{
						size: {
							"small size": "small",
							"medium size": "medium",
							"large size": "large",
							"custom size": { width: 32, height: 32 },
						},
					}}
				/>
				<Header.H2>Guidelines</Header.H2>
				<Header.H3>Best practices</Header.H3>
				<InlineMessage variant="best-practices">
					<Header.H4>General</Header.H4>
					<Paragraph>
						Use <Code>Illustration</Code> when
					</Paragraph>
					<Ul
						items={[
							<>
								<b>Guiding Users</b>: Simplify complex processes or abstract concepts with visual
								walkthroughs, step-by-step guides, or diagrams.
							</>,
							<>
								<b>Providing Context</b>: Enhance understanding and clarify information by adding
								visual elements to text-heavy content. Use illustrations to demonstrate the purpose
								of a feature or the benefits of using a product.
							</>,
						]}
					/>
					<Header.H4>Placement</Header.H4>
					<Paragraph>
						<Code>Illustration</Code> is typically used in the following places:
					</Paragraph>
					<Ul
						items={[
							<>
								Alongside text: To complement written content or show as quick-link items (e.g{" "}
								<a href="https://my2.siteimprove.com/Auth/Direct?personId=128151299&accountId=30156&back=%2FSEOv2%2F20063%2FOverview%2FIndex%3Flang%3Den-US">
									SEO overview
								</a>
								).
							</>,
							<>
								Within cards: To provide visual context. (e.g{" "}
								<GatsbyLink to="/lab/components/Visuals/Empty State">Empty state</GatsbyLink> )
							</>,
						]}
					/>
					<Header.H4>Style</Header.H4>
					<Ul
						items={[
							<>
								<b>Siteimprove Design System</b>: Adhere to Siteimprove's guidelines for color,
								typography, and spacing. If you are not using a component from Fancy, match the
								styling of your <Code>Illustration</Code> to existing components for visual
								consistency.
							</>,
							<>
								Align illustration style with the overall message and brand. If you need customised
								illustration in your project, please fill the{" "}
								<a href="https://forms.monday.com/forms/78763df477484f35bf4a403d0fd49467?r=euc1">
									form
								</a>
								, and send the request to marketing.
							</>,
							<>Choose illustrations that directly relate to the content.</>,
							<>Ensure illustrations don't overshadow essential content.</>,
							<>Be mindful of cultural interpretations of visual elements.</>,
							<>Illustrations should complement, not duplicate, written information.</>,
							<>Too many illustrations can be distracting and overwhelming.</>,
							<>
								Illustrations should never hinder the user's ability to interact with the interface.
							</>,
						]}
					/>
				</InlineMessage>
				<Header.H3>Do not use when</Header.H3>
				<InlineMessage variant="do-not-use-when">
					<Ul
						items={[
							"The message is simple and can be conveyed with text alone",
							"The illustration doesn't align with your brand's visual style.",
							"The illustration is overly complex or detailed.",
							"The illustration doesn't scale well across different screen sizes and devices.",
						]}
					/>
				</InlineMessage>
				<Header.H3>Accessibility</Header.H3>
				<InlineMessage variant="accessibility">
					<Header.H4>For designers</Header.H4>
					<Ul
						items={[
							"Provide concise, descriptive alt text for screen readers.",
							"Ensure sufficient contrast for visibility.",
						]}
					/>
					<Header.H4>For developers</Header.H4>
					<Paragraph>
						This component comes with built-in accessibility, no extra work required.
					</Paragraph>
				</InlineMessage>
				<Paragraph>
					Explore detailed guidelines for this component:{" "}
					<a href="https://siteimprove-wgs.atlassian.net/wiki/x/4wQNeQ">
						Accessibility Specifications
					</a>
				</Paragraph>
				<Header.H3>Writing</Header.H3>
				<InlineMessage variant="writing">
					<Ul
						items={[
							"Explain the illustration's purpose or connection to the content.",
							"Use short, descriptive accompanying text.",
						]}
					/>
				</InlineMessage>
			</TextContainer>
		</ContentSection>
	</>
);

const BasicUsage = () => (
	<Illustration src={IllustrationSuccess} alt="Accessibility illustration" />
);

const SizeExample = () => (
	<>
		<Illustration
			src={IllustrationSuccess}
			size={{ width: 32, height: 32 }}
			alt="Illustration with custom size"
		/>
		<Illustration src={IllustrationSuccess} size="small" alt="Small size illustration" />
		<Illustration src={IllustrationSuccess} size="medium" alt="Medium size illustration" />
		<Illustration src={IllustrationSuccess} size="large" alt="Large size illustration" />
	</>
);

const DecorativeExample = () => <Illustration src={IllustrationSuccess} decorative />;
